var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-view',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"lock-issue",attrs:{"total":_vm.total,"query-info":_vm.queryInfo},on:{"update:queryInfo":function($event){_vm.queryInfo=$event},"update:query-info":function($event){_vm.queryInfo=$event},"reload-table":_vm.renderTable},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('view-search-form',{ref:"searchFormRef",attrs:{"label-width":"55px","keyword-width":"320px","keyword-placeholder":"请输入宿舍号(如221001)","tool-list":['keyword'],"queryInfo":_vm.queryInfo},on:{"update:queryInfo":function($event){_vm.queryInfo=$event},"update:query-info":function($event){_vm.queryInfo=$event},"on-search":function($event){return _vm.renderTable(1)}}},[_c('el-form-item',{attrs:{"label":"宿舍楼","prop":"build","label-width":"55px"}},[_c('el-select',{attrs:{"size":"small","clearable":"","filterable":""},model:{value:(_vm.queryInfo.dormitoryId),callback:function ($$v) {_vm.$set(_vm.queryInfo, "dormitoryId", $$v)},expression:"queryInfo.dormitoryId"}},_vm._l((_vm.buildList),function(ref){
var dormitoryName = ref.dormitoryName;
var id = ref.id;
return _c('el-option',{key:id,attrs:{"label":dormitoryName,"value":id}})}),1)],1)],1)]},proxy:true}])},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"border":"","stripe":""}},[_c('el-table-column',{attrs:{"label":"发起时间","width":"170","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.updateTime || row.createTime)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"issueTitle","label":"问题标题","width":"180"}}),_c('el-table-column',{attrs:{"prop":"issueDescr","label":"问题描述","min-width":"280"}}),_c('el-table-column',{attrs:{"prop":"phoneModel","label":"手机型号","min-width":"280"}}),_c('el-table-column',{attrs:{"label":"现场照片","width":"120","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.issueImg)?_vm._l((JSON.parse(row.issueImg)),function(url,index){return _c('el-image',{key:index,staticStyle:{"border-radius":"4px"},attrs:{"src":("" + _vm.$fileUrl + url),"preview-src-list":[("" + _vm.$fileUrl + url)],"fit":"contain","height":"120px"}})}):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"宿舍号","width":"300"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.dormitoryName)?_c('span',[_vm._v(_vm._s(row.dormitoryName))]):_vm._e(),(row.floor)?_c('span',[_vm._v("第"+_vm._s(row.floor)+"层")]):_vm._e(),(row.roomName)?_c('span',[_vm._v(_vm._s(row.roomName))]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"反馈人","width":"160","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.stuName || row.teaName)+" "),(row.userType===2)?_c('el-tag',[_vm._v("学生")]):_vm._e(),(row.userType===3)?_c('el-tag',{attrs:{"type":"success"}},[_vm._v("教职工")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"反馈人电话","width":"160","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.stuPhone || row.teaPhone)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"反馈人部门/院系","width":"200","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.className)?[_vm._v(" "+_vm._s(row.collegeName)+_vm._s(row.majorName)+_vm._s(row.className)+" ")]:(row.teaDeptName)?[_vm._v(" "+_vm._s(row.teaDeptName)+" ")]:_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }