<!--智能锁 反馈-->
<!-- 0授权卡 1总控 2楼 3层 4设置组卡 5组卡 6设置房卡 7房卡 8退房卡-->

<template>
  <table-view
    class="lock-issue"
    :total="total"
    v-loading="loading"
    :query-info.sync="queryInfo"
    @reload-table="renderTable"
  >
    <template #header>
      <view-search-form
        ref="searchFormRef"
        label-width="55px"
        keyword-width="320px"
        keyword-placeholder="请输入宿舍号(如221001)"
        :tool-list="['keyword']"
        :queryInfo.sync="queryInfo"
        @on-search="renderTable(1)">
        <el-form-item label="宿舍楼" prop="build" label-width="55px">
          <el-select
            size="small"
            v-model="queryInfo.dormitoryId"
            clearable
            filterable>
            <el-option
              v-for="{dormitoryName,id} in buildList"
              :label="dormitoryName"
              :value="id"
              :key="id" />
          </el-select>
        </el-form-item>
      </view-search-form>
    </template>
    <!-- body -->
    <el-table :data="tableData" style="width: 100%" border stripe>
      <el-table-column label="发起时间" width="170" align="center">
        <template v-slot="{row}">
          {{ row.updateTime || row.createTime }}
        </template>
      </el-table-column>
      <el-table-column prop="issueTitle" label="问题标题" width="180" />
      <el-table-column prop="issueDescr" label="问题描述" min-width="280" />
      <el-table-column prop="phoneModel" label="手机型号" min-width="280" />
      <el-table-column label="现场照片" width="120" align="center">
        <template v-slot="{row}">
          <template v-if="row.issueImg">
            <el-image v-for="(url,index) in JSON.parse(row.issueImg)" :key="index" :src="`${$fileUrl}${url}`"
                      :preview-src-list="[`${$fileUrl}${url}`]" fit="contain" height="120px"  style="border-radius: 4px"/>
          </template>
        </template>
      </el-table-column>
      <el-table-column label="宿舍号" width="300">
        <template v-slot="{row}">
          <span v-if="row.dormitoryName">{{ row.dormitoryName }}</span>
          <span v-if="row.floor">第{{ row.floor }}层</span>
          <span v-if="row.roomName">{{ row.roomName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="反馈人" width="160" align="center">
        <template v-slot="{row}">
          {{ row.stuName || row.teaName}}
          <el-tag v-if="row.userType===2">学生</el-tag>
          <el-tag type="success" v-if="row.userType===3">教职工</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="反馈人电话" width="160" align="center">
        <template v-slot="{row}">
          {{row.stuPhone || row.teaPhone}}
        </template>
      </el-table-column>
      <el-table-column label="反馈人部门/院系" width="200" align="center">
        <template v-slot="{row}">
          <template v-if="row.className">
            {{row.collegeName}}{{row.majorName}}{{row.className}}
          </template>
          <template v-else-if="row.teaDeptName">
            {{row.teaDeptName}}
          </template>
        </template>
      </el-table-column>
    </el-table>

  </table-view>
</template>

<script>
import tableView from '@/vue/mixins/table-view'
import { pageAALockIssueApi } from '@/api/dorm/lock-issue-api'

export default {
  name: 'aaLockIssue',
  mixins: [tableView],
  data() {
    return {
      queryInfo: {
        dormitoryId: null, // 宿舍楼号 获取 list的lockNo
        keyword: null
      },
      buildList: [], // 楼栋
      floorList: 0, // 楼层
      roomList: [] // 宿舍
    }
  },
   mounted() {
    this.getDormBuild()
  },
  methods: {
    async renderTable(pageNum) {
      await this.getTableData(pageAALockIssueApi, pageNum)
    },
    // 获取楼栋
    async getDormBuild() {
      // 1 获取宿舍楼list
      try {
        const res = await this.$http.getDormBuildQuery({})
        this.buildList = res.data
      } catch (e) {
        console.error(e)
      }
    }
  }
}
</script>
