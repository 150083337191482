import request from '@/service/request'

/* 分页根据物理地址获取锁房卡指令记录信息 （也可以用于筛选已绑定该锁的学生）
* regionIds {Array} regionId=安装了电表的宿舍
* */
export const pageAALockIssueApi = (params) => {
  return request.post('/app/lockIssue/page', params)
}

/* 获取设房卡指令（设置类）发房卡
* params
* */
export const activateDormCardApi = (params) => {
  return request.post('/app/kxb/roomNumCard', params)
}
